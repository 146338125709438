<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12 col-12">
      <div class="card full-height bg-ligth">
        <div class="card-header d-flex justify-content-between">
          <h3 class="pt-1">Pdv Eventos</h3>
          <div class="col-md-8">
            <treeselect
              :multiple="false"
              :options="lista_eventos"
              placeholder="Selecione o evento..."
              v-model="eventos_id"
              @input="preenxerCampos()"
            />
          </div>
        </div>
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-9 mb-3">
              <!-- <label class="col-md-12">Eventos:*</label> -->
            </div>
            <div class="col-md-3 text-end">
              <v-btn
                color="primary"
                depressed
                elevation="2"
                outlined
                @click="rel()"
                >Relatorio</v-btn
              >
            </div>
          </div>
          <b-table
            responsive
            :fields="['nome', 'valores','total','created_at', 'ult_atualizacao', 'acoes']"
            :items="lista_pdvs_eventos"
            :per-page="perPage"
            :current-page="currentPage"
            id="pdvs_evento-table"
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            show-empty
            style="font-size: 12px;"
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #head(created_at)><span>criado</span></template>
            <template #head(ult_atualizacao)><span>atualizado
            </span></template>

            <template #cell(created_at)="{ item }">{{
              item.created_at | formatEnToBr
            }}</template>

            <template #cell(nome)="{ item }"
              ><span>{{ item.nome }} </span>
            </template>
            <template #cell(valores)="{ item }">
              <span
                class="pl-1 pr-1"
                style="font-weight: 700; border: 1.5px solid black"
                v-if="item.pdv_evento_ingressos != null"
              >
                PIX : {{ item.pdv_evento_ingressos.pix | formatMoney }}
              </span>
     

              <span
                class="ml-1 pl-1 pr-1"
                style="font-weight: 700; border: 1.5px solid black"
                v-if="item.pdv_evento_ingressos != null"
              >
                CRE : {{ item.pdv_evento_ingressos.credito | formatMoney }}
              </span>
        
              <span
                class="ml-1 pl-1 pr-1"
                style="font-weight: 700; border: 1.5px solid black"
                v-if="item.pdv_evento_ingressos != null"
              >
                DEB : {{ item.pdv_evento_ingressos.debito | formatMoney }}
              </span>
          

              <span
                class="ml-1 pl-1 pr-1"
                style="font-weight: 700; border: 1.5px solid black"
                v-if="item.pdv_evento_ingressos != null"
              >
                DIN :
                {{ item.pdv_evento_ingressos.dinheiro | formatMoney }}
              </span>
              
            </template>
            <template #cell(ult_atualizacao)="{ item }">

<span     v-if="item.pdv_evento_ingressos != null">
  {{ item.pdv_evento_ingressos.updated_at | formatDate }}
</span>

</template>
<template #cell(total)="{ item }">
              <span
                class="ml-1 pl-1 pr-1"
                style="font-weight: 700; border: 1.5px solid black"
                v-if="item.pdv_evento_ingressos != null"
              >
                TOTAL :
                {{ (parseFloat(item.pdv_evento_ingressos.dinheiro) +parseFloat(item.pdv_evento_ingressos.pix) + parseFloat(item.pdv_evento_ingressos.credito) + parseFloat(item.pdv_evento_ingressos.debito)) | formatMoney  }}
              </span>
            </template>

            <template #cell(acoes)="{ item }">
              <div class="text-right ">
                <v-tooltip
                  v-for="(btn, index) in lista_btn"
                  :key="index"
                  top
                  :color="btn.color"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="lista_permissoes_filial[btn.permisao]"
                      outlined
                      class="mr-1"
                      @click="acoes(item, btn.button)"
                      :color="btn.color"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i :class="btn.icon"></i>
                    </v-btn>
                  </template>
                  <span>{{ btn.title }}</span>
                </v-tooltip>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_pdvs_eventos.length"
            :per-page="perPage"
            aria-controls="pdvs_evento-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <v-card elevation="10" class="full-height bg-ligth">
        <div class="card-header d-flex justify-content-between">
          <div>
            <h3 class="mb-0">MEIO DE PAGAMENTOS GERAL</h3>
          </div>
          <div>
            <v-btn
              elevation="4"
              outlined
              @click="adicionar()"
              small
              color="info"
              light
            >
              <v-icon color="red" dense large>mdi-plus p</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="card-body table-responsive">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">DINHEIRO EM ESPÉCIE</th>
                  <th class="text-left">PIX ONLINE</th>
                  <th class="text-left">CREDITO SITE</th>
                  <th class="text-left">DEBITO POS</th>

                  <th class="text-left">STATUS</th>
                  <th class="text-left">CRÉDITO POS</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in ingressos" :key="index">
                  <!-- <td>
                    {{ item.id }}
                  </td> -->
                  <td>
                    <v-text-field
                      v-mask="variableWithMaskD[index]"
                      @input="valorFormatD(index)"
                      v-model="item.dinheiro_especie"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-mask="variableWithMaskP[index]"
                      @input="valorFormatP(index)"
                      v-model="item.pix_online"
                    ></v-text-field>
                  </td>

                  <td>
                    <v-text-field
                      v-mask="variableWithMask[index]"
                      @input="valorFormat(index)"
                      v-model="item.credito_site"
                    ></v-text-field>
                  </td>

                  <td>
                    <v-text-field
                      v-mask="variableWithMaskCD[index]"
                      @input="valorFormatCD(index)"
                      v-model="item.debito_pos"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-combobox
                      clearable
                      outlined
                      small-chips
                      :items="['Disponivel', 'Indisponivel']"
                      v-model="item.status"
                    ></v-combobox>
                  </td>
                  <td>
                    <v-text-field
                      v-mask="variableWithMaskCC[index]"
                      @input="valorFormatCC(index)"
                      v-model="item.credito_pos"
                    ></v-text-field>
                  </td>

                  <td>
                    <v-btn
                      @click="save()"
                      small
                      class="ma-2"
                      color="primary"
                      dark
                    >
                      SALVE
                    </v-btn>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th class="text-left">VALOR RETIRADO</th>
                  <th class="text-left">DATA RETIRADA</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item2, index) in transacoes" :key="index">
                  <!-- <td>
                    {{ item.id }}
                  </td> -->
                  <td>
                    <v-text-field
                      v-mask="variableWithMaskVal[index]"
                      @input="valorFormatval(index)"
                      v-model="item2.valor"
                    ></v-text-field>
                  </td>
                  <td>
                    <input
                      required=""
                      type="date"
                      class="form-control"
                      id="form_control_1"
                      v-model="item2.date_retirada"
                      placeholder="Data de Retirada..."
                    />
                  </td>
                  <td>
                    <v-btn
                      @click="save()"
                      small
                      class="ma-2"
                      color="primary"
                      dark
                    >
                      SALVE
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import EventoService from "@/core/services/evento.service";
import dateFormat from "@/core/mixins/dateFormat";
import moneyFormat from "@/core/mixins/moneyFormat";

export default {
  mixins: [fireAlert, dateFormat, moneyFormat],

  data() {
    return {
      eventos_id: EventoService.getEventoId(),
      form2: {
        eventos_id: null,
        dinheiro_especie: null,
        status: null,

        pix_online: null,
        credito_site: null,
        debito_pos: null,
        credito_pos: null,
      },
      currentPage: 1,
      perPage: 10,
      lista_btn: [
        {
          permisao: "c_Pdv",
          button: "entrar",
          title: "Gerenciar Evento",
          color: "warning",
          icon: "fa fa-arrow-right text-warning",
        },
        {
          permisao: "u_Pdv",
          button: "editar",
          title: "Editar registro",
          color: "info",
          icon: "far fa-edit text-primary",
        },
        {
          permisao: "lock_Pdv",
          button: "exclusao",
          title: "Bloquear/Desbloquear registro",
          color: "error",
          icon: "fas fa-trash text-danger",
        },
      ],
      variableWithMask: [],
      variableWithMaskD: [],
      variableWithMaskCD: [],
      variableWithMaskCC: [],
      variableWithMaskVal: [],
      ingressos: [],
      variableWithMaskP: [],

      transacoes: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pdvs Evento" }]);
  },

  created() {
    this.listar_pdvs();
  },
  computed: {
    lista_eventos() {
      return EventoService.getEventos().map((cat) => ({
        id: cat.id,
        label: cat.id + " - " + cat.nome_evento,
      }));
    },
    lista_pdvs_eventos() {
      // return this.$store.state.pdvsEvento.lista_pdvs_eventos;

      return this.$store.state.pdvsEvento.lista_pdvs_eventos.filter((item) => {
        if (!this.eventos_id) return true;
        return (
          String(item["eventos_id"])
            .toLowerCase()
            .indexOf(this.eventos_id + "".toLowerCase()) !== -1
        );
      });
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.pdvsEvento.mensagem_alert;
    },
    mensagem_alert1() {
      return this.$store.state.saldoTransacoes.mensagem_alert;
    },
  },
  methods: {
    rel() {
      if (!this.eventos_id) {
        this.alertMessage("warning", "Seleciona um Evento");
      } else {
        window.open(
          process.env.VUE_APP_API_URL +
            "pdvs_ingresso/relatorio/" +
            this.eventos_id,
          "_blank"
        );
      }
    },
    acoes(item, tipo) {
      if (tipo == "editar") this.atualizar(item);
      if (tipo == "exclusao") this.confirm(item);
      if (tipo == "entrar") this.entrar(item);
    },
    async listar_pdvs() {
      await this.$store.dispatch("evento/listar_eventos");

      await this.$store.dispatch("pdvsEvento/listar_pdvs_eventos");
      this.$store.dispatch("configEmpresa/atualizar", "");
    },
    atualizar(value) {
      this.$router.push({ name: "createPdvsEvento" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação devincula este pdv ao evento no sistema?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("pdvsEvento/delete_pdvs_evento", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 2000,
      }).then((r) => {
        // console.log(r.value);
      });
    },

    entrar(item) {
      this.$router.push({
        name: "incresosPdv",
        params: { pdvs_evento_id: item.id },
      });
    },
    adicionar() {
      if (this.ingressos.length == 0) {
        this.ingressos.push({
          id: null,
          credito_site: null,
          dinheiro_especie: null,
          debito_pos: null,
          credito_pos: null,
          pix_online: null,
          status: null,
        });
      } else {
        this.transacoes.push({
          date_retirada: null,
          valor: null,
        });
      }
    },
    save() {
      this.form2.eventos_id = this.eventos_id;
      this.form2.status = this.ingressos[0].status;

      this.form2.transferencias = JSON.stringify(this.transacoes);

      this.form2.credito_site = parseFloat(
        this.ingressos[0].credito_site.replace("R$", "").replace(",", ".")
      );
      this.form2.dinheiro_especie = parseFloat(
        this.ingressos[0].dinheiro_especie.replace("R$", "").replace(",", ".")
      );
      this.form2.debito_pos = parseFloat(
        this.ingressos[0].debito_pos.replace("R$", "").replace(",", ".")
      );
      this.form2.credito_pos = parseFloat(
        this.ingressos[0].credito_pos.replace("R$", "").replace(",", ".")
      );
      this.form2.pix_online = parseFloat(
        this.ingressos[0].pix_online.replace("R$", "").replace(",", ".")
      );

      if (this.ingressos[0].id == null) {
        this.confirmSave("cria");
      } else {
        this.form2.id = this.ingressos[0].id;
        this.confirmSave("edita");
      }
    },
    valorFormat(index) {
      let pa = "";
      for (let i = 0; i < this.ingressos[index].credito_site.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMask[index] = "R$" + pa + "#,##";
    },
    valorFormatval(index) {
      let pa = "";
      for (let i = 0; i < this.transacoes[index].valor.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMaskVal[index] = "R$" + pa + "#,##";
    },
    valorFormatD(index) {
      let pa = "";
      for (let i = 0; i < this.ingressos[index].dinheiro_especie.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMaskD[index] = "R$" + pa + "#,##";
    },
    valorFormatCD(index) {
      let pa = "";
      for (let i = 0; i < this.ingressos[index].debito_pos.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMaskCD[index] = "R$" + pa + "#,##";
      console.log(this.variableWithMask);
    },
    valorFormatCC(index) {
      let pa = "";
      for (let i = 0; i < this.ingressos[index].credito_pos.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMaskCC[index] = "R$" + pa + "#,##";
      console.log(this.variableWithMask);
    },
    valorFormatP(index) {
      let pa = "";
      for (let i = 0; i < this.ingressos[index].pix_online.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMaskP[index] = "R$" + pa + "#,##";
    },

    async confirmSave(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Pdv no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch(
        "saldoTransacoes/create_saldo_transacoes",
        this.form2
      );
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert1,
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch(
        "saldoTransacoes/update_saldo_transacoes",
        this.form2
      );
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert1,
      });
    },
    async preenxerCampos() {
      await this.$store.dispatch(
        "saldoTransacoes/listar_saldo_transacoes",
        this.eventos_id
      );

      this.ingressos = this.$store.state.saldoTransacoes.saldo_transacoes;

      this.ingressos.forEach((element, index) => {
        this.transacoes = JSON.parse(element.transferencias);

        element.credito_site =
          "R$" +
          (!element.credito_site
            ? "0,00"
            : element.credito_site.replace("R$", "").replace(".", ","));

        element.debito_pos =
          "R$" +
          (!element.debito_pos
            ? "0,00"
            : element.debito_pos.replace("R$", "").replace(".", ","));
        element.credito_pos =
          "R$" +
          (!element.credito_pos
            ? "0,00"
            : element.credito_pos.replace("R$", "").replace(".", ","));

        element.dinheiro_especie =
          "R$" +
          (!element.dinheiro_especie
            ? "0,00"
            : element.dinheiro_especie.replace("R$", "").replace(".", ","));
        element.pix_online =
          "R$" +
          (!element.pix_online
            ? "0,00"
            : element.pix_online.replace("R$", "").replace(".", ","));
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>